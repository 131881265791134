var chatmenueopen = false;
$.fn.select2.defaults.set( "theme", "bootstrap4" );
$(document).ready(function() {
    loadMenue();
    initTiny('.tinymceeditor');
});


function loadMenue() {
    $('#createexcel').off('click');
    $('#createexcel').on('click', function() {
        window.open('zettelexcel/'+$('[name="jahr"]').val()+'/2', "Zweitfenster", "width=300,height=400,left=100,top=200");
    });
}

var tinyMCE = '';
function initTiny(dataselector) {
    tinymce.init({
        selector: dataselector,
    });
}